.statusCard-mainDiv {
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 10px;
  padding: 5px;
  padding-bottom: 12px;
  position: relative;
}

.paid-status{
  border: 1px solid #1AB275;
}

.pending-status{
  border: 1px solid #F3B400
}

.rejected-status{
  border: 1px solid #E42125;
}

.detailsCard-infoDiv {
  display: flex;
}

.detailsCard-viewBtn {
  display: flex;
  justify-content: end;
  margin-top: 17px;
}

.detailsCard-border {
  border: 1px solid #396AF2;
}

.statusCard-map-outerDiv {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.statusCard-label {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  word-wrap: break-word;
  color: #666666;
}

.statusCard-value {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  margin-top: 5px;
  font-size: 11px;
  line-height: 13px;
  word-wrap: break-word;
  color: #3A3A3A;
  text-transform: capitalize;
}

.paid-value{
  color: #1AB275;
}

.pending-value{
  color: #F3B400
}

.rejected-value{
  color: #E42125;
}

.move-to-end div:nth-child(3) {
  text-align: end;
}

.move-to-end div:nth-child(6) {
  text-align: end;
}

.statusCard-label-value {
  width: 33.33%;
  margin-top: 7px;
  padding: 0 3px;
  margin-bottom: 10px;
}

.upBtnBottom{
  width: 100%;
  text-align: end;
}

.detailsCard-Uploaded-title{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin: 0 0 0 3px;
}

.viewInvoices-container {
  position: relative;
  margin: 10px 10px 5px 10px;
  width: calc(50% - 20px)!important;
  width: fit-content;
  padding: 4px 0;
  display: flex;
  align-items: center;
  background: #FAFAFA;
  border: 1px solid #0984E3;
  border-radius: 4px;
  cursor: pointer;
}

.viewInvoices-btn{
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  background-color: ghostwhite;
  padding: 1px 0;
}

.viewInvoices-file-div{
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.viewInvoices-name {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #3A3A3A;
}

.viewInvoices-size {
  text-align: left;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: #3a3a3a;
}

.viewInvoices-image-container {
  display: flex;
  gap: 8px;
  margin-bottom: 4px;
  padding: 0 8px;
}

.viewInvoices-date {
  border-top: 1px solid #0984e3;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #3a3a3a;
  width: 100%;
  text-align: start;
  padding: 4px 0 0 8px;
}

.no-records {
  font-weight: 500;
  margin-left: 25px;
}