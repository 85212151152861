.Genericforms-text {
    font-style: normal;
    font-weight: 400;
    font-size: var(--md-text);
    line-height: 15px;
    margin-top: 16px;
    color: #7C7C7C;
}

.GenericPetforms-input {
    height: 37px;
    background: rgba(108, 92, 231, 0.1);
    border-radius: 5px;
}

.Genericforms-radio-text {
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    line-height: 15px;
    color: #3A3A3A;
}

.radiobutton-genricpet-main {
    display: flex;
    align-items: center;
    padding: 10px;
}

.radiobutton-genricpet-main .Genericforms-text {
    margin-top: 4px;
}

.textinput-genricpet-main,
.numberinput-genric-main {
    padding: 10px;
    padding-top: 0px;
}

.generic-mydatepicker {
    position: relative;
}

.generic-calendar-icon {
    top: 0px;
}

.generic-status-update-input::placeholder {
    font-size: var(--md-text);
}

.generic-status-update-input {
    height: 37px;
    background: rgba(5, 138, 238, 0.1);
    border-radius: 5px;
    border: #7C7C7C;
    width: 100%;
    padding: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #3A3A3A;
}

.petdropdown .btn,
.petdropdown .show>.btn-secondary.dropdown-toggle,
.petdropdown .btn-secondary:hover {
    height: 37px;
    background: rgba(5, 138, 238, 0.1);
    width: -webkit-fill-available;
    font-weight: 400;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: .15s ease-in-out;
    box-shadow: none;
    text-align: start;
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    color: #3A3A3A;
}

.petdropdown .btn-group {
    display: block;
}

.petdropdown {
    align-items: center;
    margin-top: 1px;
    padding: 10px;
}

.genericpet-petdropdown .dropdown-toggle::after {
    position: sticky;
    left: 100%;
}

.genericpet-petdropdown .dropdown-toggle::after[aria-expanded="true"] {
    transform: rotate(180deg);
}

.genericpet-petdropdown .petdropdown .btn,
.petdropdown .show>.btn-secondary.dropdown-toggle,
.petdropdown .btn-secondary:hover[aria-expanded="true"] {
    color: #212529;
    border-bottom: transparent;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.genericpet-petdropdown .petdropdown .btn,
.petdropdown .show>.btn-secondary.dropdown-toggle,
.petdropdown .btn-secondary {
    padding: 10px;
}

.petdropdown .label-text {
    font-style: normal;
    font-weight: 400;
    font-size: var(--md-text);
    line-height: 15px;
    margin-top: 15px;
    color: #7C7C7C;
}

.petdropdown .dropdown-menu.show {
    width: 100%;
    transform: translate3d(0px, 37px, 0px) !important;
}

.form-control {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #3A3A3A;
}

.form-control:focus {
    box-shadow: none;
}

.react-datepicker-wrapper .generic-status-update-input {
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: none !important;
    outline: none;
}

.radiobuttongeneric [type="checkbox"],
[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%23808080'/%3e%3c/svg%3e") !important;
}

.radiobuttongeneric.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%23ff0000'/%3e%3c/svg%3e") !important;
}

.radiobuttongeneric.form-check-input:checked {
    background-color: white;
    border-color: #E42125;
    border-width: 1px;
}

.generic-petdropdownitems {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #3A3A3A;
    padding: 5px 10px;
}

.generic-petdropdownitems-main {
    z-index: 0;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    padding-top: 3px;
    padding-bottom: 3px;
    transform: translate3d(0px, 36.5px, 0px);
    border-top: transparent;
    position: relative !important;
    top: -37px !important;

}

.numberinput-genricpet-main {
    padding: 0 10px;
}

.AssignedblockOpencases {
    background: rgba(26, 178, 117, 0.1);
    border: 1px solid rgba(26, 178, 117, 0.1) !important;
}

.AssignedblockReincases {
    background: rgba(235, 175, 0, 0.1);
    border: 1px solid rgba(9, 132, 227, 0.1) !important;
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
    top: 3px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: var(--primary-color);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: rgb(247, 189, 190);
}

.react-datepicker__day--outside-month {
    visibility: hidden;
}

.open-case-color {
    background-color:  rgba(26, 178, 117, 0.1) !important;
}

.allotted-case-color {
    background-color: rgba(5, 138, 238, 0.1) !important;
}

.rein-case-color {
    background-color: rgba(235, 175, 0, 0.1) !important;
}

.reject-case-color {
    background-color: rgba(124, 20, 75, 0.1) !important;
}

.numberinput-genric-mb-main input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}