.download-doc-btn {
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
}
.download-doc-btn button {
    height: 25px;
    background: var(--primary-color);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: #FFFFFF;
    font-size: var(--lg-text);
    border: none;
}
.pdf-canvas canvas {
    width: 100% !important;
}
.pg-viewer-wrapper, .pg-viewer {
    overflow: unset;
    width: 100%;
    height: 100%;
    min-height: 328px;
}
.file-upload-modal .modal-body {
    width: 100%;
}
.image-modal-body {
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.pan-container div img {
    height: 400px;
    object-fit: contain;
}
.pg-viewer-wrapper .photo-viewer-container,
.pg-viewer-wrapper .pg-driver-view,
.video-container {
    width: 100% !important;
    height: 100% !important;
    min-height: 328px;
}
.video-container video,
.pg-viewer-wrapper img {
    width: 100% !important;
    height: 100% !important;
}
.video-container audio {
    width: 100% !important;
}
.pg-viewer-wrapper .document-container {
    padding: 0;
    width: 100%;    
}
.view-image {
    width: 100%;
    height: auto;
}
.view-video > div {
    width: 100% !important;
    height: auto !important;
}
.view-audio {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(400px - 2rem);
}
.view-audio > audio {
    width: 100%;
}

.view-file #pdf-download {
    display: none;
}

.react-pdf__Page {
    --scale-factor: 0.5179084827697357;
}

.react-pdf__Page__canvas {
    width: 316px;
    height: 410px;
}