.casesAndInvoiceTracker .casesAndInvoiceTracker-container {
  padding: 15px 12px;
}
.casesAndInvoiceTracker .switch-btn-container {
  margin: 0 10px 10px;
  display: flex;
  height: 32px;
  padding: 2px;
  border-radius: 8px;
  background: rgba(118, 118, 128, 0.12);
}
.casesAndInvoiceTracker .switch-btn {
  border-radius: 7px;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  background: #FFF;
  box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.12) !important;
  color: #000;
  font-family: 'Inter', sans-serif;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.08px;
  outline: unset;
  width: 50%;
}
.casesAndInvoiceTracker .not-btn {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.08px;
}
.casesAndInvoiceTracker .switch-btn.btn-invoice {
  animation: slideInLeft 0.5s forwards;
  font-weight: 600;
}

.casesAndInvoiceTracker .switch-btn.btn-cases {
  animation: slideInRight 0.5s forwards;
  font-weight: 600;
}
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}
.casesAndInvoiceTracker .main-content {
  width: 100%;
  height: 100%;
}
.casesAndInvoiceTracker .main-content .border-container {
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  background: #FFF;
  padding: 12px;
}
.casesinvoice-sub-text {
  color: #90949A;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-bottom: 20px;
}
.casesinvoice-piechart-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-heading {
  color: #3A3A3A;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-bottom: 16px;
}
.table-container {
  margin-top: 30px;
}
.casesAndInvoiceTracker-container .cases-support-section .main-text {
  color: #BBB;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-bottom: 6px;
  margin-top: 20px;
}
.casesAndInvoiceTracker-container .cases-support-section .sub-text {
  color: #BBB;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.casesAndInvoiceTracker-container .cases-support-section .sub-text span {
  color: #396AF2;
  font-weight: 600;
  text-decoration: underline;
}
.casesinvoicedoutpiechart-main-heading {
  color: #3A3A3A;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.casesinvoicedoutpiechart-main-heading.content-heading {
  font-size: 11px;
  margin: 40px 10px 20px;
}
.casesinvoicedoutpiechart-performance-dispdata {
  width: 10px;
  height: 10px;
  margin-right: 4px;
  border-radius: 10px;
}
.casesinvoicedoutpiechart-labelstart {
  color: #90949A;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.gradientYellow{
  fill: url(#gradientYellow);
}
.gradientBlue{
  fill: url(#gradientBlue);
}
.gradientRed{
  fill: url(#gradientRed);
}